<template>
  <router-view />
</template>

<script>
export default {
  name: "BlankView",
};
</script>

<style scoped>
</style>
