import Vue from "vue";
import Router from "vue-router";
import BlankView from '@/layouts/BlankView'
Vue.use(Router);

//创建router实例对象，去管理一组一组的路由规则
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            redirect: "/",
            component: () => import("@/layouts/index.vue"),
            children: [
                {
                    name: "home",
                    path: "/",
                    component: () => import("@/views/Home/"),
                },
                {
                    name: "bloglist",
                    path: "/blog",
                    component: () => import("@/views/BlogList/"),
                },
                {
                    name: "blog",
                    path: "/blogs/:titlePath",
                    component: () => import("@/views/Blog/"),
                    props: true
                },
                {
                    name: "contactus",
                    path: "/contact-us",
                    component: () => import("@/views/ContactUS/"),
                },
                {
                    name: "aboutus",
                    path: "/about-us",
                    component: () => import("@/views/AboutUS/"),
                },
                {
                    name: "research",
                    path: "/research",
                    redirect: "/research/listing-service",
                    component: BlankView,
                    children: [
                        {
                            name: "listingService",
                            path: "listing-service",
                            component: () => import("@/views/Research/ListingService/"),
                        },
                        {
                            name: "analysisReport",
                            path: "analysis-report",
                            component: () => import("@/views/Research/AnalysisReport/"),
                        },
                    ]

                }, {
                    name: "officialVerify",
                    path: "/official-verify",
                    component: () => import("@/views/officialVerify/"),
                },
                {
                    name: "modTeam",
                    path: "/mod-team",
                    component: () => import("@/views/ModTeam/"),
                },
            ],
        },
    ],
});

//暴露router
export default router;
